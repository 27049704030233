import "./App.css";
import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";

function App() {
  return (
    <ChakraProvider>
      <h1> William Lines. </h1>
    </ChakraProvider>
  );
}

export default App;
